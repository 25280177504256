<template>
	<div class="contact">
		<b-container>
			<base-page-title image="bg-orcamento-title">
				Solicitar
				<br />
				Orçamento
			</base-page-title>

			<div class="contact-form">
				<b-row>
					<b-col>
						<h3 class="contact-form-title">Itens Selecionados</h3>
						<div class="empty text-muted" v-if="!Object.keys(cart).length">
							<p class="d-block">Nenhum item no orçamento</p>
							<b-button class="mt-3" variant="primary" :to="{ name: 'product.list' }">
								<span>Clique aqui para adicionar produtos</span>
							</b-button>
						</div>
						<div class="list">
							<div class="item" v-for="(item, index) in cart" :key="index">
								<b-button tabindex="-1" size="sm" variant="danger">
									<i class="la la-minus" @click="removeCartItem(item, index)"></i>
								</b-button>
								<b-form-input class="qty" v-model="item.qty" size="sm" @update="update(item)" />
								<b-button size="sm" variant="info" tabindex="-1">
									<i class="la la-plus" @click="addCartItem(item)"></i>
								</b-button>
								<div class="title">
									<span class="name">{{ item.name }}</span>
									<span class="variant" v-if="item.variant">{{ item.variant }}</span>
								</div>
							</div>
						</div>
					</b-col>
					<b-col sm="6">
						<h3 class="contact-form-title mt-5 mt-md-0">Dados necessários</h3>
						<form @submit.prevent="onSave">
							<b-form-group>
								<b-form-input placeholder="Razão Social" v-model="reg.company" required></b-form-input>
							</b-form-group>
							<b-row>
								<b-col>
									<b-form-group>
										<b-form-input placeholder="CNPJ" v-model="reg.doc" required></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group>
										<b-form-input placeholder="Telefone" v-model="reg.phone" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group>
										<b-form-input placeholder="E-mail" v-model="reg.email" required></b-form-input>
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-group>
										<b-form-input placeholder="Cidade - UF" v-model="reg.city" required></b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<b-form-group>
								<b-form-textarea
									v-model="reg.message"
									required
									rows="6"
									placeholder="Escreva sua mensagem. 
Não é necessário adicionar itens ao orçamento caso queira
somente tirar uma dúvida."
								></b-form-textarea>
							</b-form-group>
							<b-alert :show="sended" variant="success">
								Mensagem enviada com sucesso, em breve retornaremos o contato
							</b-alert>
							<div class="text-right">
								<b-btn variant="primary" type="submit" :disabled="loading">
									<b-spinner v-if="loading"></b-spinner>
									<span v-else>Enviar</span>
								</b-btn>
							</div>
						</form>
					</b-col>
				</b-row>
			</div>

			<div class="contact-info">
				<a
					href="https://api.whatsapp.com/send?1=pt_br&phone=+5551995890690"
					target="_blank"
					class="contact-info-bx contact-info-bx-wa"
				>
					<i class="lab la-whatsapp"></i>
					<span>(51) 99589-0690</span>
				</a>
				<div class="contact-info-bx">
					<i class="las la-phone-volume"></i>
					<span>(51) 3559-3282</span>
				</div>
			</div>
		</b-container>

		<div class="contact-map">
			<p class="text-center">
				R. Plácido de Castro, 28 - Sete de Setembro, Sapiranga - RS, 93819-252
				<br />wellvin@wellvin.com.br
			</p>
			<div class="contact-map-iframe">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3467.833043587827!2d-50.98994718490732!3d-29.63759198203224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95193f0c46be8ccd%3A0xf40e51e5d7eb12c0!2sR.%20Pl%C3%A1cido%20de%20Castro%2C%2028%20-%20Sete%20de%20Setembro%2C%20Sapiranga%20-%20RS%2C%2093819-252!5e0!3m2!1spt-BR!2sbr!4v1629165574565!5m2!1spt-BR!2sbr"
					width="100%"
					height="450"
					style="border: 0"
					allowfullscreen=""
					loading="lazy"
				></iframe>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import BasePageTitle from '../components/BasePageTitle.vue'
export default {
	components: { BasePageTitle },
	data: () => ({
		loading: false,
		sended: false,
		error: false,
		reg: {}
	}),
	computed: {
		cart() {
			return this.$store.getters.cart
		}
	},
	mounted() {
		this.$store.dispatch('getCart')
	},
	methods: {
		addCartItem(item) {
			item.qty = item.qty + 1
			this.$store.commit('SET_CART', this.cart)
		},
		removeCartItem(item, index) {
			item.qty = item.qty - 1
			if (item.qty <= 0) {
				delete this.cart[index]
			}
			this.$store.commit('SET_CART', this.cart)
		},
		onSave() {
			const data = { ...this.reg, ...{ cart: this.cart } }
			this.loading = true
			axios
				.post(process.env.VUE_APP_MAIL_URL, data)
				.then(() => {
					this.loading = false
					this.sended = true
					this.error = false
				})
				.catch(() => {
					this.error = true
				})
		},
		update(item) {
			item.qty = item.qty * 1
			this.$store.commit('SET_CART', this.cart)
		}
	}
}
</script>

<style lang="scss">
.contact {
	.contact-map {
		margin-top: 8rem;
		p {
			font-size: 2rem;
		}
	}

	.contact-map-iframe {
		margin-top: 6rem;
	}

	.list {
		> .item {
			display: flex;
			align-items: center;
			padding: 2px 0;

			.qty {
				padding: 1rem 1rem;
				width: 5rem;
				text-align: center;
				margin: 0 5px;
			}

			.btn {
				border-radius: 0.4rem;
				font-size: 1.6rem;
			}

			> .title {
				margin-left: 1rem;
				line-height: 1;
				> .name {
					display: block;
				}

				> .variant {
					font-size: 1.2rem;
					color: $primary;
				}
			}
		}
	}

	.contact-info {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 3rem;
		font-weight: 700;
		.contact-info-bx {
			display: flex;
			align-items: center;
			margin: 2rem;
			color: $body-color;
		}
		a.contact-info-bx {
			&:hover {
				text-decoration: none;
				span {
					text-decoration: underline;
				}
				i {
					transform: scale(1.2);
				}
			}
		}
		i {
			font-size: 4rem;
			margin-right: 1rem;
			transition: all 0.2s;
		}
		.contact-info-bx-wa {
			i {
				color: green;
			}
		}
	}

	.contact-form {
		margin-bottom: 8rem;
	}

	@include media-breakpoint-down(sm) {
		.contact-info {
			flex-direction: column;
			.contact-info-bx {
				justify-content: center;
			}
		}
	}
}
</style>
